import React from 'react'
import { Helmet } from 'react-helmet'

import LayoutStorelocator from '../../layout/storelocator'

import Locator from '../../components/locator'

import Retailer from '../../svg/retailer.svg'

import Puzzle from '../../svg/storelocator/puzzle.svg'
import Chart from '../../svg/storelocator/chart.svg'
import Clock from '../../svg/storelocator/clock.svg'
import Sync from '../../svg/storelocator/sync.svg'
import Map from '../../svg/storelocator/map.svg'

const Page = () => (
        <LayoutStorelocator language="en">

            <Helmet>
                <title>Zupr - Storelocator</title>
                <meta name="description" content="With Zupr you show consumers online where products are available in local, physical stores." />
            </Helmet>

            <section className="text-left image-right divider">
                <div className="inner">

                    <div className="text">
                        <h2>Discover the Zupr store locator</h2>
                        <p>Zupr adds a completely new dimension to store locator software. Classic store locators require you to manage sales location data by yourself. With Zupr’s software you work in tandem with retailers to map the local availability of your products. For example, if a sales location changes its opening hours or its product range, these changes will be directly visible on your website. Not only does this save you time, but it also assures that the consumer always has up-to-date information.</p>
                        <a className="btn btn-demo" href="mailto:sales@zupr.io?subject=Store locator demo">Request a free trial</a>
                    </div>

                    <div className="image">
                        <Retailer />
                    </div>

                </div>
            </section>

            <section className="text-center image-bottom divider">
                <div className="inner">

                    <div className="text">
                        <h2>There is more: from store locator to product locator</h2>
                        <p>Most store locators can only show sales locations at the brand level, whereas consumers are interested in seeing where they can buy a specific product. Therefore, Zupr works at the product level, allowing you to show in which stores your individual products are in stock. This gives consumers all the information they are looking for!</p>
                        <Locator />
                    </div>

                </div>
            </section>

            <section className="text-center">
                <div className="inner">

                    <div className="text">
                        <h2>Work in tandem with your local retailers</h2>
                        <p>By digitally sending Zupr your product information and product images, retailers no longer have to do this themselves. This helps you to keep control over your brand identity and retailers to more easily communicate their product range and stock. The ZuprCharger app allows even the smallest retailer to participate.</p>
                    </div>

                </div>
            </section>

            <section className="text-center grey">
                <div className="inner">

                    <div className="text">
                        <h2>Advantages of Zupr</h2>
                        <div className="usps">
                            <div className="block with-icon">
                                <h3><Puzzle />Easy integration</h3>
                                <p>You can easily integrate the Zupr store locator into your website. It is possible to visually adapt the store locator to match the corporate identity of your brand.</p>
                            </div>
                            <div className="block with-icon">
                                <h3><Chart />Analytics/Statistics</h3>
                                <p>A user-friendly dashboard offers attractively presented real-time data. Heat maps, for example, offer direct insight into the local demand for your products.</p>
                            </div>
                            <div className="block with-icon">
                                <h3><Clock />Start immediately</h3>
                                <p>As soon as your account has been created, you are ready to go. In your personal admin environment you can add products and directly invite retailers to provide data about their stock and product range.</p>
                            </div>
                            <div className="block with-icon">
                                <h3><Sync />Up-to-date</h3>
                                <p>Since retailers manage the data themselves, consumers always have the correct opening hours and contact information at their disposal. Stock information is also visible, which is of course greatly important to consumers.</p>
                            </div>
                            <div className="block with-icon">
                                <h3><Map />Route planner</h3>
                                <p>Consumers can immediately plan a route to the closest store that is selling your products, and can also sort results by price and availability.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="text-center divider">
                <div className="inner">

                    <div className="text">
                        <h2>Pricing</h2>
                        <p>For pricing information please contact us at <a href="mailto:sales@zupr.io">sales@zupr.io</a>.</p>
                    </div>

                </div>
            </section>

            <section className="contact-boost">
                <div className="inner">
                    <h2>Questions?</h2>
                    <p>For questions please contact the Zupr team.</p>
                    <p>
                        <a href="mailto:support@zupr.nl?subject=Question about Zupr" className="email">support@zupr.nl</a>
                        <a href="tel:+31854011306" className="telephone">+31 (0)85 40 11 306</a>
                    </p>
                </div>
            </section>

    </LayoutStorelocator>
)

export default Page
